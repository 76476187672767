import React, { useState } from 'react'
import { ViewName } from '../../../views/manifest'
import { selectViews } from '../store/viewsSlice'

import socket from '../socket'
import view from '../../../views/orders/view'
import { useSelector } from 'react-redux'

export interface ScheduleComponentProps {
  viewName: ViewName
}

export const ScheduleComponent: React.FC<ScheduleComponentProps> = ({viewName}) => {

  const state = useSelector(selectViews)

  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [hours, setHours] = useState(0)
  const [days, setDays] = useState(0)

  const handleSubmit = () => {
    socket.emit("setUpdateSchedule", viewName, {
      seconds: seconds,
      minutes: minutes,
      hours: hours,
      days: days
    })
  }

  return (
    <div>
    <div style={{ textAlign: 'center' }}>
      <br/>
      <h4>Set Schedule</h4>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
        <div>
          Seconds:  
          <input type="number" min="0" max="60" value={seconds} onChange={(e) => setSeconds(parseInt(e.target.value, 10))} />
        </div>
        <div>
          Minutes:  
          <input type="number" min="0" max="60" value={minutes} onChange={(e) => setMinutes(parseInt(e.target.value, 10))} />
        </div>
        <div>
          Hours:  
          <input type="number" min="0" max="24" value={hours} onChange={(e) => setHours(parseInt(e.target.value, 10))} />
        </div>
        <div>
          Days:  
          <input type="number" min="0" max="7" value={days} onChange={(e) => setDays(parseInt(e.target.value, 10))} />
        </div>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
    <div style={{ textAlign: 'center' }}>
      Existing Schedule: {state[viewName].schedule.seconds}:{state[viewName].schedule.minutes}:{state[viewName].schedule.hours}:{state[viewName].schedule.days}
    </div>
    </div>
  )

}
