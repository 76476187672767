import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewName } from '../../views/manifest'
import { ViewState, updateViewExists, updateRowscn, updateRowCount, actionIsStarted, actionIsCompleted, actionIsFailed, clearActions, wasJustUpdated, setUpdateSchedule,  } from './store/viewsSlice'

import { ViewComponent } from './components/View'
import { ActionsComponent } from './components/Actions'
import { selectViews } from './store/viewsSlice'

import './App.css';
import socket from './socket'
import { ScheduleSpec } from '../../events';

socket.on('connect', () => {
  console.log('Connected to server');
});

const App: React.FC = () => {

  const dispatch = useDispatch();  
  let [log, setLog] = useState([""])
  let views: Record<ViewName, ViewState> = useSelector(selectViews)

  useEffect(() => {

    const onActionStarted = (action: string) => {
        dispatch(actionIsStarted(action))
    }
    const onActionSucceeded = (action: string) => {
      dispatch(actionIsCompleted(action))
    }
    const onActionFailed = (action: string, err: string) => {
      dispatch(actionIsFailed(action, err))
    }
    const onSetRowScn = (viewName: ViewName, rowscn: number | "Unknown") => {
      dispatch(updateRowscn(viewName, rowscn))
    }
    const onSetViewExists = (viewName: ViewName, exists: boolean | "Unknown") => {
      dispatch(updateViewExists(viewName, exists))
    }
    const onSetRowCount = (viewName: ViewName, count: number | "Unknown") => {
      dispatch(updateRowCount(viewName, count))
    }
    const onUpdateHasRun = (viewName: ViewName) => {
      dispatch(wasJustUpdated(viewName))
    }
    const onSetUpdateSchedule = (viewName: ViewName, scheduleSpec: ScheduleSpec) => {
      dispatch(setUpdateSchedule(viewName, {
        seconds: scheduleSpec.seconds,
        minutes: scheduleSpec.minutes,
        hours: scheduleSpec.hours,
        days: scheduleSpec.days
      }))
    }

    socket.on("actionStarted", onActionStarted)
    socket.on("actionSucceeded", onActionSucceeded)
    socket.on("actionFailed", onActionFailed)
    socket.on("setrowscn", onSetRowScn)
    socket.on("setviewexists", onSetViewExists)
    socket.on("setrowcount", onSetRowCount)
    socket.on("updateHasRun", onUpdateHasRun)
    socket.on("setupdateschedule", onSetUpdateSchedule)

    return () => {
      socket.off("actionStarted", onActionStarted)
      socket.off("actionSucceeded", onActionSucceeded)
      socket.off("actionFailed", onActionFailed)
      socket.off("setrowscn", onSetRowScn)
      socket.off("setviewexists", onSetViewExists)
      socket.off("setrowcount", onSetRowCount)
      socket.off("updateHasRun", onUpdateHasRun)
      socket.off("setupdateschedule", onSetUpdateSchedule)
    }

  }, [])


 const viewComponents = []
  for (let viewName of Object.keys(views)) {
    viewComponents.push(
    <div>
      <hr/>
      <ViewComponent 
        viewName={viewName as ViewName} 
        viewState={views[viewName as ViewName]}></ViewComponent>
    </div>
  )}
  return (
    <div className="App">
      <h1>A2000 Mirror V2</h1>
      <div>
        <ActionsComponent></ActionsComponent>
      </div>
      <div>{viewComponents}</div>
    </div>
  );
}

export default App;
