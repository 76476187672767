import { io, Socket } from 'socket.io-client';
import { ServerToClientEvents, ClientToServerEvents } from '../../events'

let socket: Socket<ServerToClientEvents, ClientToServerEvents>;

if (process.env.REACT_APP_MVDEV_MODE == 'local') {
    socket = io('http://localhost:8080');
} else {
    socket = io(`https://api.mirror.${process.env.REACT_APP_ROOT_URL}`);
}

export default socket
