import { configureStore } from '@reduxjs/toolkit'
import { viewsSlice } from './viewsSlice'

  
export const store = configureStore({
    reducer: {
        views: viewsSlice.reducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
