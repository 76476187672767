import React from 'react';
import { ViewName } from '../../../views/manifest'
import { ViewState } from '../store/viewsSlice'
import { useDispatch, useSelector } from 'react-redux';

import socket from '../socket'
import { ScheduleComponent } from './Schedule'

export interface ViewComponentProps {
    viewName: ViewName
    viewState: ViewState
}

export const ViewComponent: React.FC<ViewComponentProps> = ({viewName, viewState}) => {

    const dispatch = useDispatch();  

    const updateButtonHandler = () => {
      socket.emit("runUpdateProgram", viewName)
    }

    const getStatusButtonHandler = () => {
      socket.emit("runGetViewStatusProgram", viewName)
    }

    const createButtonHandler = () => {
      socket.emit("runCreateViewProgram", viewName)
    }

    const dropButtonHandler = () => {
      socket.emit("runDropViewProgram", viewName)
    }

    const lastUpdatedStr = (val: Date | "Never") => {
      if (val == "Never") {
        return "Never"
      } else {
        return val.toString()
      }
    }

    return (
        <div>
            <h3>View {viewName} at ROWSCN {viewState.rowscn}</h3>
            <h4>Last Updated: {lastUpdatedStr(viewState.lastUpdated)}</h4>
            {viewState.exists != "Unknown" && <h4>View Exists in Mirror: {viewState.exists.toString()}</h4>}
            {viewState.rowsCount != "Unknown" && <h4>Row Count: {viewState.rowsCount}</h4>}
            <button onClick={createButtonHandler}>Create</button>
            <button onClick={dropButtonHandler}>Drop</button>
            <button onClick={updateButtonHandler}>Update</button>
            <button onClick={getStatusButtonHandler}>Get View Status</button>

            <ScheduleComponent viewName={viewName}></ScheduleComponent> 
        </div>
    )
} 

