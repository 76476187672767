import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearActions, selectActions } from '../store/viewsSlice'
import socket from '../socket'


export const ActionsComponent: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const actions = useSelector(selectActions)

    const makeScrollBox = (title: string, content: string) => {
        return (
        <div>
            <h4>{title}</h4>
            <div
                style={{
                    height: '120px',
                    width: '300px',
                    border: '1px solid #ccc',
                    overflow: 'auto',
                    margin: '10px' 
                }}
            >
                {content}
            </div>
        </div>
        )
    }

    const dropButtonHandler = () => {
        dispatch(clearActions())
    }

    const resetCacheHandler = () => {
        socket.send("resetCache")
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {makeScrollBox("Actions in Progress", actions.actionsInProgress.join("\n"))}
            {makeScrollBox("Completed Actions", actions.actionsSucceded.join("\n"))}
            {makeScrollBox("Failed Actions", actions.actionsFailed.join("\n"))}
            <button onClick={dropButtonHandler}>Drop</button>
            <button onClick={resetCacheHandler}>Reset Cache</button>
        </div>   
    )
 
}